import React from "react";

interface Props {
  fill: string;
  width: number;
  height: number;
}
const ShieldCheck = ({ fill = "white", width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M3.99935 4.75603V7.16536C3.99944 8.02231 4.1827 8.86934 4.53683 9.6497C4.89096 10.43 5.40779 11.1257 6.05268 11.69L7.99935 13.3934L9.94602 11.69C10.5909 11.1257 11.1078 10.4301 11.4619 9.64972C11.8161 8.86936 11.9993 8.02232 11.9993 7.16536V4.75603L7.99935 3.25603L3.99935 4.75603ZM3.09868 3.66936L7.99935 1.83203L12.9 3.67003C13.0271 3.71766 13.1366 3.80291 13.2139 3.91441C13.2912 4.02591 13.3326 4.15835 13.3327 4.29403V7.16536C13.3327 9.28336 12.418 11.2987 10.824 12.694L7.99935 15.1654L5.17468 12.694C4.38666 12.0045 3.75514 11.1545 3.32248 10.201C2.88981 9.24746 2.66599 8.21246 2.66602 7.16536V4.29403C2.66607 4.15835 2.70752 4.02591 2.78483 3.91441C2.86214 3.80291 2.97163 3.71699 3.09868 3.66936ZM7.29202 8.67736L9.64935 6.32003C9.77508 6.19859 9.94349 6.1314 10.1183 6.13292C10.2931 6.13443 10.4603 6.20455 10.5839 6.32815C10.7075 6.45176 10.7776 6.61897 10.7791 6.79376C10.7807 6.96856 10.7135 7.13696 10.592 7.2627L7.76335 10.092C7.63833 10.217 7.46879 10.2872 7.29202 10.2872C7.11524 10.2872 6.9457 10.217 6.82068 10.092L5.40668 8.67803C5.28524 8.5523 5.21805 8.38389 5.21957 8.2091C5.22109 8.0343 5.2912 7.86709 5.4148 7.74349C5.53841 7.61988 5.70562 7.54977 5.88041 7.54825C6.05521 7.54673 6.22361 7.61393 6.34935 7.73536L7.29202 8.67803V8.67736Z"
    />
  </svg>
);
export default ShieldCheck;
